import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { useState } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import colors from "./colors"
import Logo from "../images/logo.svg"
import { FaFacebook, FaTwitter, FaInstagram, FaBars } from "react-icons/fa"

const Brand = styled(Link)`
  color: white;
  text-decoration: none;
  @media only screen and (min-width: 768px) {
  }
`

const HeaderLink = styled(Link)`
  color: white;
  padding: 1em;
  text-decoration: none;
  height: max-content;
  &:hover {
    color: ${colors.yellow};
  }
`

const Header = ({ siteTitle }) => {
  const [menuActive, setMenuActive] = useState(false)
  const toggleMenu = () => {
    setMenuActive(!menuActive)
  }
  return (
    <>
      <div
        css={css`
          height: 2.5em;
          display: flex;
          justify-content: center;
          background: ${colors.gold};
          svg {
            height: 2em;
            width: 2em;
            padding: 0.25em;
          }
        `}
      >
        <a href="https://www.facebook.com/dingusgamestx/">
          <FaFacebook />
        </a>
        <a href="https://www.twitter.com/dingusgames/">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/dingusgamestx/">
          <FaInstagram />
        </a>
      </div>
      <header
        css={css`
        background: ${colors.red};
        
        /* border-bottom: solid 2em ${colors.gold}; */

        .active-header-link {
          border-bottom: 4px solid ${colors.yellow};
          color: ${colors.yellow};
        }
        svg {
          height: 128px;
          width: 256px;
        }
        h1 {
          color: ${colors.yellow};
          font-family: avenir;
          line-height: 1;
          margin: 0;
        }
        h2 {
          line-height: 1;
          margin: 0;
        }
        nav {
          display: flex;
          width: 260px;
          justify-content: flex-end;
          box-sizing: border-box;

          svg {
            color: ${colors.yellow};
          }
        }
      `}
      >
        <div
          css={css`
            display: none;
            @media only screen and (min-width: 768px) {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1em;
              display: flex;
              width: 100%;
              box-sizing: border-box;
              a {
                height: max-content;
              }
            }
          `}
        >
          <nav>
            <HeaderLink activeClassName="active-header-link" to="/blog">
              <h2>BLOG</h2>
            </HeaderLink>
            <HeaderLink activeClassName="active-header-link" to="/games">
              <h2>GAMES</h2>
            </HeaderLink>
          </nav>
          <div style={{ textAlign: "center" }}>
            <Logo />
            <Brand to="/">
              <h1>DINGUS</h1>
              <h2>GAMES</h2>
            </Brand>
          </div>
          <nav>
            <HeaderLink activeClassName="active-header-link" to="/about">
              <h2>ABOUT</h2>
            </HeaderLink>
            <HeaderLink activeClassName="active-header-link" to="/contact">
              <h2>CONTACT</h2>
            </HeaderLink>
          </nav>
        </div>
        <div
          css={css`
            nav {
              display: flex;
              padding: 0.5em 2em;
              align-items: center;
              width: 100%;
              box-sizing: border-box;
              justify-content: space-between;
              text-align: center;
            }
            button {
              border: none;
              background: none;
              width: max-content;
              height: max-content;
              width: 80px;
              cursor: pointer;
              svg {
                max-height: 3em;
                width: 3em;
              }
            }
            svg {
              height: 5em;
              width: 5em;
            }
            a {
              font-size: 1.4em;
            }

            @media only screen and (min-width: 768px) {
              display: none;
            }
          `}
        >
          <nav>
            <Logo />
            <Brand to="/">
              <h1>DINGUS</h1>
              <h2>GAMES</h2>
            </Brand>
            <button onClick={toggleMenu}>
              <FaBars />
            </button>
          </nav>
        </div>
      </header>
      <nav
        css={css`
          display: ${menuActive ? "flex" : "none"};
          box-sizing: border-box;
          width: 100%;
          padding: 1em;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: ${colors.yellow};
          a {
            color: ${colors.red};
          }
          a:hover {
            color: ${colors.red};
            text-decoration: underline;
          }
          h2 {
            margin: 0;
            font-weight: 900;
          }
        `}
      >
        <HeaderLink to="/blog">
          <h2>BLOG</h2>
        </HeaderLink>
        <HeaderLink to="/games">
          <h2>GAMES</h2>
        </HeaderLink>
        <HeaderLink to="/about">
          <h2>ABOUT</h2>
        </HeaderLink>
        <HeaderLink to="/contact">
          <h2>CONTACT</h2>
        </HeaderLink>
      </nav>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
