/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Global, css } from "@emotion/core"
import Footer from "./footer"
import colors from "./colors"
import SEO from "./seo"
import Helmet from "react-helmet"
import "../../static/fonts.css"

const Container = ({ children, title }) => (
  <div
    css={css`
      margin: 0 auto;
      max-width: 60ch;
      padding: 0 1.0875rem 1.45rem;

      h1 {
        font-size: 3em;
        text-align: center;
        margin: 0;
      }

      @media only screen and (max-width: 768px) {
        h1 {
          font-size: 2em;
        }

        p {
          line-height: 1.5;
        }
      }
    `}
  >
    {children}
  </div>
)

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <SEO location={location} />
      <Global
        styles={css`
          * {
            margin: 0;
            padding: 0;
          }

          hr {
            margin: 1em 0;
          }
          html,
          body {
            font-family: Raleway, Avenir, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
            background: #f2f2f2f2;
            line-height: 2;
            font-size: 16px;
          }
          li {
            text-align: justify;
          }
          main {
            background: white;
            margin-top: 1em;
            padding: 1em;
            box-sizing: border-box;
            outline: 1px lightgray solid;
          }
          p {
            text-align: justify;
          }
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin-bottom: 1em;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            line-height: 1;
          }

          a {
            color: ${colors.red};
          }
        `}
      />
      <div
        css={css`
          min-height: 100vh;
          position: relative;
        `}
      >
        <div
          css={css`
            padding-bottom: 8em;
          `}
        >
          <Header />
          <div
            css={css`
              margin: 0 auto;
              max-width: 960px;
              padding: 0 1.0875rem 1.45rem;
            `}
          >
            <main>
              <Container title={location}>{children}</Container>
            </main>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
