import React from "react"
import { css } from "@emotion/core"
import colors from "./colors"

export default () => (
  <footer
    css={css`
      background: ${colors.red};
      min-height: 128px;
      padding: 1em;
      color: ${colors.yellow};
      border-top: 1em solid ${colors.gold};
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
    `}
  >
    <h3>©{new Date().getFullYear()} Dingus Games LLC</h3>
  </footer>
)
